import PagesSlider from "./pages-slider";
import Wizard from "./wizard";

/**
 * Pages slider component
 *
 */
export default class Sliders {
    constructor() {
        const pagesSlider = document.getElementsByClassName("ce-projectcontentelements_page_slider");

        for (let i = 0; i < pagesSlider.length; i++) {
            new PagesSlider(pagesSlider[i] as HTMLElement);
        }

        const widzardSlider = document.getElementById("wizard-scrollbar");
        if (widzardSlider) {
            new Wizard();
        }
    }
}
