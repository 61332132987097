/**
 *
 * Scroller represents an internal link scroller.
 * The href="#target" (anchor) or the data-scroll="#target" (any other element) is the target to scroll to. Make sure an ID is unique...
 * data-scroll-offset="#header" is an optional setting to subtract the height of given element. Only one element allowed (either ID or CLASS)
 *
 * Example:
 * <a href="#main" class="scroller" data-scroll data-scroll-offset="#header">Text</a>
 *
 */

const Event = {
    on: (event, selector, fn) => {
        //  Split on space
        const events = event.split(" ");

        //  Check if selector is window pass the window object, otherwise it will be the document providing the selector
        if (selector === window) {
            if (events.length > 0) {
                for (const event of events) {
                    window.addEventListener(event, fn, false);
                }
            }
        } else {
            const selectors = document.querySelectorAll(selector);

            if (events.length > 0) {
                for (const event of events) {
                    if (selectors.length > 0) {
                        for (const element of selectors) {
                            element.addEventListener(event, fn, false);
                        }
                    }
                }
            }
        }
    },
};

export default class Scroller {
    constructor() {
        Event.on("click", "[data-scroll]", (event) => {
            event.preventDefault();

            const clickedElement = event.currentTarget;
            const headerHeight = document.querySelector("#header").getBoundingClientRect().height;
            let scroll = true;
            let offset = 0;
            let target: string;

            if (clickedElement.getAttribute("data-scroll")) {
                target = clickedElement.getAttribute("data-scroll");
            } else {
                scroll = false;
                console.warn("No target found for scroller");
            }

            if (clickedElement.getAttribute("data-scroll-offset")) {
                const offsetElements = document.querySelectorAll(clickedElement.getAttribute("data-scroll-offset"));

                if (offsetElements.length > 0) {
                    offsetElements.forEach((offsetElement) => {
                        offset += offsetElement.getBoundingClientRect().height;
                    });
                }
            }

            const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

            let scrollBehavior: "smooth" | "auto" = "smooth";
            if (!mediaQuery || mediaQuery.matches) {
                scrollBehavior = "auto";
            }

            if (scroll && target) {
                window.scrollTo({
                    top: document.querySelector(target).getBoundingClientRect().top - offset - headerHeight,
                    behavior: scrollBehavior,
                });
            }
        });
    }
}
