export abstract class BaseSlider {
    private _slider: HTMLElement;
    private _buttonNext: HTMLElement;
    private _buttonPrev: HTMLElement;
    private _totalItems: number;

    constructor(slider: HTMLElement, totalItems: number, buttonNext: HTMLElement, buttonPrev: HTMLElement) {
        this._slider = slider;
        this._buttonNext = buttonNext;
        this._buttonPrev = buttonPrev;
        this._totalItems = totalItems;

        if (!this._slider || !this._buttonNext || !this._buttonPrev) {
            return;
        }

        if (this._totalItems > 0) {
            this.setButtonEventListener();
        }
    }

    public getSlider(): HTMLElement {
        return this._slider;
    }

    public getButtonNext(): HTMLElement {
        return this._buttonNext;
    }

    public getButtonPrev(): HTMLElement {
        return this._buttonPrev;
    }

    public getTotalItems(): number {
        return this._totalItems;
    }

    private setButtonEventListener(): void {
        const slidingAmount = Math.round(this._slider.getBoundingClientRect().width / this._totalItems);

        this._buttonNext.addEventListener("click", () => this.scrollElementByAmount(this._slider, slidingAmount));
        this._buttonPrev.addEventListener("click", () => this.scrollElementByAmount(this._slider, -slidingAmount));
    }

    private scrollElementByAmount(element: HTMLElement, amount: number): void {
        element.scrollBy({ left: amount, behavior: "smooth" });
    }
}
