export default class MobileMenu {
    private mobileMenuContainer: HTMLElement;
    private mobileMenuTrigger: HTMLElement;
    private activeMenuId: string;
    private mainMenuId = "menu-0";

    constructor() {
        this.mobileMenuContainer = document.getElementById("mobile-menu-container");
        this.mobileMenuTrigger = document.getElementById("mobile-menu-trigger");
        this.activeMenuId = this.mainMenuId;

        this.setPrimaryMenu();
        this.setEventListeners();
    }

    private setEventListeners() {
        this.mobileMenuTrigger.addEventListener("click", () => {
            this.setPrimaryMenu();
            if (document.body.classList.contains("mobile-menu-open")) {
                this.mobileMenuTrigger.querySelector("span").innerText = "Menu";
            } else {
                this.mobileMenuTrigger.querySelector("span").innerText = "Sluit";
            }
            this.mobileMenuTrigger.classList.toggle("icon-menu");
            this.mobileMenuTrigger.classList.toggle("icon-cancel");
        });

        Array.from(this.mobileMenuContainer.querySelectorAll("li.has-children > button")).map((submenuButton) => {
            submenuButton.addEventListener("click", () => {
                const submenuId = submenuButton.getAttribute("aria-controls");
                const submenu = document.getElementById(submenuId);

                submenu.setAttribute("aria-hidden", "false");
            });
        });

        Array.from(this.mobileMenuContainer.querySelectorAll(".sub-level > li > button.back-to-menu-button")).map(
            (backButton) => {
                backButton.addEventListener("click", () => {
                    this.setPrimaryMenu();
                });
            }
        );
    }

    private setPrimaryMenu() {
        this.setMenu(this.mainMenuId);
    }

    private setMenu(menuId: string) {
        this.activeMenuId = menuId;

        this.mobileMenuContainer.querySelector("#" + menuId).setAttribute("aria-hidden", "false");
        Array.from(this.mobileMenuContainer.querySelectorAll("div > ul")).map((menu) => {
            if (menu.id !== menuId) {
                menu.setAttribute("aria-hidden", "true");
            }
        });
        Array.from(this.mobileMenuContainer.querySelectorAll("div > ul > li")).map((menuItem) => {
            menuItem.setAttribute("aria-expanded", "false");
        });
    }
}
