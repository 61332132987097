import { addClass, removeClass, trapFocus } from "../utils";

import HeaderSearch from "./header-search";
import MegaMenu from "./mega-menu";
import MobileMenu from "./mobile-menu";

export default class Header {
    private menubarItemsWithSubmenu: NodeListOf<HTMLElement>;

    constructor() {
        this.menubarItemsWithSubmenu = document
            .getElementById("main-nav")
            .querySelectorAll("nav>div>ul>li.has-children");

        this.setEventListeners();
        new HeaderSearch();
        new MegaMenu();
        new MobileMenu();
    }

    private setEventListeners() {
        Array.from(this.menubarItemsWithSubmenu).map((menubarItem) => {
            const openMenu = () => {
                const submenuList = menubarItem.querySelector("ul");

                this.closeAllSubmenus();
                this.openSubmenu(menubarItem);
                trapFocus(submenuList);
            };

            menubarItem.onmouseover = () => {
                openMenu();
            };

            menubarItem.onmouseout = () => {
                this.closeAllSubmenus();
            };
        });

        this.handleKeydown();

        window.addEventListener("scroll", this.checkScrollTop);
    }

    private checkScrollTop() {
        if (window.scrollY === 0) {
            removeClass(document.body, "scrolling");
        }
        if (window.scrollY > 80) {
            addClass(document.body, "scrolling");
        }
    }

    private openSubmenu(menuItem) {
        menuItem.setAttribute("aria-expanded", "true");
    }

    private closeAllSubmenus() {
        Array.from(this.menubarItemsWithSubmenu).map((menuItem) => {
            menuItem.setAttribute("aria-expanded", "false");
        });
    }

    private isElement(target: EventTarget): HTMLElement {
        return (<HTMLElement>target).classList !== undefined ? <HTMLElement>target : undefined;
    }

    private handleKeydown() {
        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.closeAllSubmenus();
                const parentMenuListItem = this.isElement(e.target).closest(".has-children");
                if (parentMenuListItem) {
                    (<HTMLElement>parentMenuListItem.firstElementChild).focus();
                }
            }

            // if key arrow down
            if (e.key === "ArrowDown") {
                // If target was a menu item
                if (
                    this.isElement(e.target) &&
                    this.isElement(e.target).classList.contains("trigger-submenu") &&
                    Array.from(this.menubarItemsWithSubmenu).includes(this.isElement(e.target).parentElement)
                ) {
                    e.preventDefault();
                    this.closeAllSubmenus();
                    this.openSubmenu(this.isElement(e.target).parentElement);
                    const allFocusableElements: HTMLElement[] = Array.from(
                        this.isElement(e.target).nextElementSibling.querySelectorAll(
                            'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                        )
                    );
                    const currentFocusableElement = allFocusableElements.find(
                        (element) => element === document.activeElement
                    );
                    if (currentFocusableElement === undefined) {
                        allFocusableElements[0].focus();
                    } else {
                        const nextFocusableElement = allFocusableElements.find((element, index) => {
                            return element === currentFocusableElement && index < allFocusableElements.length - 1;
                        });
                        if (nextFocusableElement !== undefined) {
                            nextFocusableElement.focus();
                        }
                    }
                    return;
                }
                // if target is anchor link
                if (
                    this.isElement(e.target).tagName === "A" &&
                    this.isElement(e.target).getAttribute("role") === "menuitem"
                ) {
                    e.preventDefault();
                    const menu = this.getMenuFromMenuItem(e.target);

                    if (menu !== undefined) {
                        this.setFocusToNextMenuitem(e.target);
                    }
                    return;
                }
            }
            if (e.key === "ArrowUp") {
                if (
                    this.isElement(e.target).tagName === "A" &&
                    this.isElement(e.target).getAttribute("role") === "menuitem"
                ) {
                    e.preventDefault();
                    const menu = this.getMenuFromMenuItem(e.target);

                    if (menu !== undefined) {
                        this.setFocusToPreviousMenuitem(e.target);
                    }
                    return;
                }
            }
        });
    }

    /**
     * Sets the focus to the next menu item
     * @param {HTMLElement} menu
     * @param {HTMLElement} currentMenuitem
     * @returns {void}
     */
    private setFocusToNextMenuitem(currentMenuitem) {
        let newMenuitem;
        const closestListItem = currentMenuitem.closest("li");
        const closestMenu = closestListItem.closest("ul[role='menu']");

        if (closestListItem.nextElementSibling) {
            newMenuitem = closestListItem.nextElementSibling.querySelector('a[role="menuitem"]');
        } else {
            newMenuitem = closestMenu.querySelector("li:first-child>[role='menuitem']");
        }

        this.setFocusToMenuitem(newMenuitem);
    }

    /**
     * Sets the focus to the previous menu item
     * @param {HTMLElement} menu
     * @param {HTMLElement} currentMenuitem
     * @returns {void}
     */
    private setFocusToPreviousMenuitem(currentMenuitem) {
        let newMenuitem;
        const closestListItem = currentMenuitem.closest("li");
        const closestMenu = closestListItem.closest("ul[role='menu']");

        if (closestListItem.previousElementSibling) {
            newMenuitem = closestListItem.previousElementSibling.querySelector('a[role="menuitem"]');
        } else {
            newMenuitem = closestMenu.querySelector("li:last-child>[role='menuitem']");
        }

        this.setFocusToMenuitem(newMenuitem);
    }

    /**
     * Get the parent menu from the menu item
     * @param {HTMLElement} menuItem
     * @returns {HTMLElement | undefined}
     */
    private getMenuFromMenuItem(menuItem) {
        if (menuItem.parentElement.parentElement.getAttribute("role") === "menu") {
            return menuItem.parentElement.parentElement;
        }
        return undefined;
    }

    private setFocusToMenuitem(newMenuitem) {
        newMenuitem.focus();
    }
}
