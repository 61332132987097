import { addClass, removeClass } from "../utils";

export default class Tooltip {
    private tooltipClasses: NodeListOf<HTMLElement>;
    private tooltipHolders: NodeListOf<HTMLElement>;

    constructor() {
        const tooltipClass = ".dictionary-tooltip a";
        const tooltipHolder = ".dictionary-tooltip";
        let timer;

        this.tooltipClasses = document.querySelectorAll(tooltipClass);
        this.tooltipHolders = document.querySelectorAll(tooltipHolder);

        this.tooltipHolders.forEach((tooltipHolder) => {
            const tooltipTitle = tooltipHolder.getAttribute("data-tooltip-title");
            const tooltipDescription = tooltipHolder.getAttribute("data-tooltip-description");

            tooltipHolder
                .querySelector("a")
                .insertAdjacentHTML(
                    "beforeend",
                    "" +
                        '<div class="tooltip">' +
                        '<span class="tooltipTitle">' +
                        tooltipTitle +
                        "</span>" +
                        '<span class="tooltipBody">' +
                        tooltipDescription +
                        "</span>" +
                        "</div>"
                );
        });

        this.tooltipClasses.forEach((tooltipClass) => {
            tooltipClass.addEventListener("mouseover", (e) => {
                removeClass(<HTMLElement>e.target, "active");
                addClass((<HTMLElement>e.target).parentElement, "active");
            });
            tooltipClass.addEventListener("focus", (e) => {
                removeClass(<HTMLElement>e.target, "active");
                addClass((<HTMLElement>e.target).parentElement, "active");
            });

            tooltipClass.addEventListener("mouseleave", (e) => {
                timer = setTimeout(() => {
                    // set timer to be able to move with the mouse from the link to the tooltip
                    removeClass((<HTMLElement>e.target).parentElement, "active");
                }, 500);
            });
            tooltipClass.addEventListener("blur", (e) => {
                timer = setTimeout(() => {
                    // set timer to be able to move with the mouse from the link to the tooltip
                    removeClass((<HTMLElement>e.target).parentElement, "active");
                }, 500);
            });
        });

        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.tooltipHolders.forEach((tooltipHolder) => {
                    removeClass(tooltipHolder, "active");
                });
            }
        });
    }
}
