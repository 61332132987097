import { addClass, removeClass } from "../utils";

type CareProviderResult = {
    code: number;
    title: string;
    region_code: string;
    region: string;
};

export default class FindCareProvider {
    protected COOKIE_NAME = "careProvider";
    private form: HTMLFormElement;
    private container: Element;
    private searchContainer: Element;
    private resultsContainer: Element;

    constructor(container: Element, searchContainer: Element, resultsContainer: Element) {
        this.container = container;
        this.searchContainer = searchContainer;
        this.resultsContainer = resultsContainer;
        this.form = <HTMLFormElement>this.searchContainer.getElementsByClassName("form-search-care-provider")[0];

        if (this.form) {
            this.initialize();
        } else {
            console.error("No form found in FindCareProvider widget");
        }
    }

    private initialize() {
        const careProviderCookie = document.cookie
            .split(";")
            .find((cookie) => cookie.trim().startsWith(this.COOKIE_NAME));

        if (careProviderCookie) {
            this.container.setAttribute("data-view", "results");
            this.container.getElementsByClassName("overlay-cta-widget__header");

            const careProvider = JSON.parse(careProviderCookie.split("=")[1]);
            if (!!careProvider && !isNaN(careProvider?.code) && careProvider?.title !== "") {
                this.setCurrentCareProvider(careProvider);
            }
        } else {
            this.container.setAttribute("data-view", "search");

            document.querySelectorAll("[data-care-provider-buttons-holder]").forEach((buttonHolder) => {
                addClass(<HTMLElement>buttonHolder, "no-care-provider-selected");
            });
        }

        this.switchView(this.container.getAttribute("data-view"));
        this.addEventListeners();
    }

    private addEventListeners() {
        this.form.addEventListener("submit", (event: Event) => {
            event.preventDefault();
            const formData = new FormData(this.form);
            const zipcode = formData.get("zipcode").slice(0, 4);

            fetch(`?type=123&tx_projecttemplate_care_providers[demand][postalCode]=${zipcode}`).then(
                async (response) => {
                    const data = <CareProviderResult[]>await response.json();

                    this.setCurrentCareProvider(data[0]);
                    this.switchView("results");
                }
            );
        });

        const viewTriggers = document.querySelectorAll("[data-trigger-view]");
        viewTriggers.forEach((trigger) => {
            trigger.addEventListener("click", (event: Event) => {
                event.preventDefault();
                const view = (<HTMLElement>event.target).getAttribute("data-trigger-view");

                this.switchView(view);
            });
        });
    }

    private switchView(view: string) {
        if (view === "search") {
            this.container.setAttribute("data-view", view);
            this.searchContainer.setAttribute("aria-hidden", "false");
            this.resultsContainer.setAttribute("aria-hidden", "true");
        } else {
            this.container.setAttribute("data-view", "results");
            this.searchContainer.setAttribute("aria-hidden", "true");
            this.resultsContainer.setAttribute("aria-hidden", "false");
        }
    }

    private setCurrentCareProvider(careProvider: CareProviderResult) {
        careProvider.region = careProvider?.region?.toLowerCase();
        document.cookie = `${this.COOKIE_NAME}=${JSON.stringify(careProvider)};expires=${new Date(
            Date.now() + 1000 * 3600 * 24 * 14
        ).toUTCString()};path=/`;

        // Hide all buttons
        document.querySelectorAll("[data-care-provider-uid]").forEach((careProvider) => {
            careProvider.setAttribute("aria-hidden", "true");
        });

        // Show active button
        const careProviderButton = document.querySelectorAll(`[data-care-provider-uid="${careProvider.code}"]`);
        if (careProviderButton.length > 0) {
            Array.from(careProviderButton).forEach((cpButton) => {
                cpButton.setAttribute("aria-hidden", "false");
            });
        }

        document.querySelectorAll("[data-care-provider-placeholder]").forEach((placeholder) => {
            placeholder.setAttribute("aria-hidden", "true");
        });

        document.querySelectorAll("[data-care-provider-preview]").forEach((preview) => {
            preview.textContent = "Zorgkantoor " + careProvider.title;
        });

        document.querySelectorAll("[data-care-provider-region-preview]").forEach((preview) => {
            preview.textContent = careProvider.region;
        });

        document.querySelectorAll("[data-care-provider-buttons-holder]").forEach((buttonHolder) => {
            removeClass(<HTMLElement>buttonHolder, "no-care-provider-selected");
            addClass(<HTMLElement>buttonHolder, "care-provider-selected");
        });
    }
}
