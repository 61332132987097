export default class HeaderSearch {
    private header: HTMLElement;
    private sInput: HTMLInputElement;
    private active: boolean;
    private submitButton: HTMLButtonElement;

    constructor() {
        this.active = false;
        this.header = <HTMLElement>document.getElementById("header");
        this.sInput = <HTMLInputElement>document.getElementById("search-input");
    }

    private setSearchActive(): void {
        this.active = !this.active;

        if (this.active) {
            this.header.classList.add("search-bar-open");
            this.sInput.focus();
        } else {
            this.header.classList.remove("search-bar-open");
            this.sInput.value = "";
        }
    }

    private submitSearch(event: Event): void {
        if (this.sInput.value.length === 0) {
            event.preventDefault();
            return this.setSearchActive();
        }
    }
}
