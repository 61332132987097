import { trapFocus } from "../utils";

export default class MegaMenu {
    private hamburgerMenu: HTMLElement;
    private hamburgerMenuButtons: NodeListOf<Element>;

    constructor() {
        this.hamburgerMenu = document.getElementById("mega-menu");
        this.hamburgerMenuButtons = document.querySelectorAll(".mega-menu-trigger");

        this.setEventListeners();
    }

    private setEventListeners() {
        Array.from(this.hamburgerMenuButtons).map((hamburgerMenuButton) => {
            hamburgerMenuButton.addEventListener("click", () => {
                if (document.body.classList.contains("mega-menu-open")) {
                    this.hamburgerMenu.setAttribute("aria-hidden", "true");
                } else {
                    this.hamburgerMenu.setAttribute("aria-hidden", "false");
                    trapFocus(this.hamburgerMenu);
                }
            });
        });

        this.handleKeydown();
    }

    private handleKeydown() {
        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                if (document.body.classList.contains("mega-menu-open")) {
                    this.hamburgerMenu.setAttribute("aria-hidden", "true");
                    document.body.classList.remove("mega-menu-open");
                }
            }
        });
    }
}
