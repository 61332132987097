/* eslint-disable @typescript-eslint/ban-ts-comment */
export default class Trigger {
    private openClassName = "-open";

    constructor() {
        const triggers = document.querySelectorAll("[data-trigger]");

        for (let i = 0; i < triggers.length; i++) {
            const trigger = triggers[i];
            trigger.addEventListener("click", (e) => {
                this.toggle((<HTMLElement>e.target).getAttribute("data-trigger"));
            });
        }

        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                document.body.classList.forEach((i) => {
                    if (i.includes(this.openClassName)) {
                        document.body.classList.remove(i);
                    }
                });
            }
        });
    }

    private toggle(target) {
        if (!target) console.error("No target specified");

        document.body.classList.toggle(target + this.openClassName);
    }
}
