import { BaseSlider } from "../traits/base-slider";
import { addClass, hasClass } from "../utils";

/**
 * Wizard component
 *
 * See below for the required elements
 * @example
 * <div>
 *    <button type="button" title="Vorige" id="wizard-button-prev">&#8592;</button>
 *    <div id="wizard-scrollbar-wrapper">
 *        <div id="widzard-scrollbar"> // this should use scrol snap styles
 *            <a>Item 1</a>
 *            <a class="active">Item 2</a>
 *            <a>Item 3</a>
 *        </div>
 *    </div>
 *    <button type="button" title="Volgende" id="wizard-button-next">&#8594;</button>
 * </div>
 */
export default class Wizard extends BaseSlider {
    private wizardItems: HTMLCollectionOf<HTMLAnchorElement>;
    private currentIndex: number;
    private sliderWrapper: HTMLElement;

    constructor() {
        try {
            const wizardContainer = document.getElementById("wizard-siblings");
            const slider = document.getElementById("wizard-scrollbar"),
                btnNext = document.getElementById("wizard-button-next"),
                btnPrev = document.getElementById("wizard-button-prev"),
                sliderWrapper = document.getElementById("wizard-scrollbar-wrapper"),
                sliderSlides = slider.getElementsByTagName("a"),
                totalSlides = sliderSlides?.length ?? 0;

            super(slider, totalSlides, btnNext, btnPrev);

            this.currentIndex = 0;
            this.wizardItems = sliderSlides;
            this.sliderWrapper = sliderWrapper;

            if (this.getTotalItems() > 0) {
                this.initializeWizard();
            }

            addClass(wizardContainer, "active");
        } catch (e: unknown) {
            console.error("Error initializing wizard, " + e);
        }
    }

    private initializeWizard() {
        for (let n = 0; n < this.wizardItems.length; n++) {
            if (hasClass(this.wizardItems[n], "active")) {
                this.currentIndex = n;
            }
        }

        this.resizeObserver();

        this.scrollToIndex(this.currentIndex);
    }

    private scrollToIndex(index: number): void {
        if (index < 0 || index > this.getTotalItems()) {
            return;
        }

        if (window.innerWidth < 768) {
            this.getSlider().scrollTo(
                this.wizardItems[index].offsetLeft - this.wizardItems[index].getBoundingClientRect().width / 2,
                0
            );
        } else {
            this.getSlider().scrollTo(
                this.wizardItems[index].offsetLeft - this.wizardItems[index].getBoundingClientRect().width,
                0
            );
        }
    }

    private resizeObserver() {
        const resizeObserver = new ResizeObserver(() => {
            const sliderWidth = Array.from(this.wizardItems)
                .map((item) => item.getBoundingClientRect().width)
                .reduce((a, b) => a + (b + 40)); // + 40 is the margin-left of the wizard-item's

            if (sliderWidth < this.sliderWrapper.getBoundingClientRect().width) {
                this.getButtonNext().style.display = "none";
                this.getButtonPrev().style.display = "none";
            } else {
                this.getButtonNext().style.display = "flex";
                this.getButtonPrev().style.display = "flex";
            }
        });

        resizeObserver.observe(this.getSlider());
    }
}
