import { BaseSlider } from "../traits/base-slider";

/**
 * Pages slider component
 *
 */
export default class PagesSlider extends BaseSlider {
    constructor(sliderWrapper: HTMLElement) {
        const slider: HTMLElement = sliderWrapper.querySelector(".pages-slider"),
            totalItems: number = sliderWrapper.getElementsByTagName("article").length,
            buttonNext: HTMLElement = sliderWrapper.querySelector(".pages-slider-button-next"),
            buttonPrev: HTMLElement = sliderWrapper.querySelector(".pages-slider-button-prev");

        super(slider, totalItems, buttonNext, buttonPrev);

        this.init();
    }

    private init() {
        if (this.getTotalItems() <= 1) {
            this.getButtonNext().style.display = "none";
            this.getButtonPrev().style.display = "none";
        }
    }
}
