/**
 * Cookie Notice
 *
 * This module is a simple implementation of a CookieWall
 * It gives the user only a notice about the use of cookies.
 *
 * By clicking on the agreement on the buttons "Yes" and "no",
 * the notice will be removed and won't be shown within the life
 * span of the cookie.
 *
 * Basic HTML usage:
 * <div data-cookie-notice="wall|bar" data-cookie-expire="365" class="cookie-notice">
 *      <a data-set-cookie="no" href="#">No</a>
 *      <a data-set-cookie="yes" href="#">Yes</a>
 * </div>
 *
 * Future implementation will contain the actual setting of the
 * TrackingCookies by either Google Analytics, GTM or Hotjar.
 */

import { addClass, removeClass } from "../utils";

export default class CookieNotice {
    protected COOKIE_NAME = "tracking_allowed";
    private cookieNotice: HTMLElement;
    private enableTracking: boolean;

    /**
     * Constructor
     */
    constructor() {
        this.cookieNotice = document.querySelector("[data-cookie-notice]");
        this.enableTracking = false;

        this.init();
    }

    private init() {
        if (this.cookieNotice) {
            this.handleUserInteraction();

            if (this.checkDoNotTrackOption()) {
                if (this.readCookie(this.COOKIE_NAME) === null) {
                    this.showCookieWall();
                } else {
                    if (this.readCookie(this.COOKIE_NAME) === "1") {
                        this.enableTracking = true;
                        window.dispatchEvent(new Event("site:allow-tracking"));
                    }
                }
            }
        }
    }

    createCookie(name: string, value: boolean, days?: number) {
        let expires = "";

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + Number(value) + expires + "; path=/";
    }

    private readCookie(name: string) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";");

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    private handleUserInteraction() {
        const buttons = this.cookieNotice.querySelectorAll("[data-set-cookie]");

        buttons.forEach((button) => {
            button.addEventListener("click", (ev) => {
                ev.preventDefault();

                const choice = (<HTMLElement>ev.currentTarget).getAttribute("set-cookie");

                if (choice === "yes") {
                    window.dispatchEvent(new Event("site:allow-tracking"));

                    this.enableTracking = true;
                }

                this.createCookie(this.COOKIE_NAME, this.enableTracking, 180);
                this.hideCookieWall();
            });
        });
    }

    private showCookieWall() {
        addClass(document.body, "cookie-notice-open");
    }

    private hideCookieWall() {
        removeClass(document.body, "cookie-notice-open");
    }

    private checkDoNotTrackOption() {
        //  Respect do not track for all browsers. Except for IE 10 and 11 where we ignore it
        const doNotTrackIE =
            navigator.appVersion.indexOf("MSIE 10") !== -1 ||
            (navigator.userAgent.indexOf("Trident") !== -1 && navigator.userAgent.indexOf("rv:11") !== -1);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
        doNotTrack = !doNotTrack || doNotTrack == "unspecified" || doNotTrack == "0";

        return doNotTrack || doNotTrackIE;
    }
}
