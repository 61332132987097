import { addClass, removeClass } from "../utils";

export default class Footer {
    private footer: Element;
    constructor() {
        this.footer = document.getElementsByTagName("footer")[0];

        if (this.footer) {
            this.initialize();
        }
    }

    private initialize() {
        // Check if the cols in the footer are empty
        const cols = this.footer.getElementsByClassName("footer-cell");
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].innerHTML.trim() === "") {
                addClass(cols[i], "hidden");
                addClass(cols[i], "lg:block");
            } else {
                removeClass(cols[i], "hidden");
                removeClass(cols[i], "lg:block");
            }
        }
    }
}
