import "../Scss/main.scss";

import Accordions from "a11y-accordion-component";
import CookieNotice from "./components/cookie-notice";
import FindCareProvider from "./components/find-care-provider";
import Footer from "./components/footer";
import Header from "./components/header";
import Sliders from "./components/slider";
import Tooltip from "./components/tooltip";
import Trigger from "./components/trigger";
import Videos from "./components/videos";
import Scroller from "./components/scroller";
import PrintPage from "./components/print-page";
import { removeClass } from "./utils";

class App {
    constructor() {
        document.addEventListener("DOMContentLoaded", function () {
            new Header();
            removeClass(document.body, "no-js");
        });
        document.addEventListener("readystatechange", function () {
            if (document.readyState === "complete") {
                Accordions.init();
                new CookieNotice();
                new PrintPage();
                new Scroller();
                new Sliders();
                new Tooltip();
                new Trigger();
                new Videos();
                new Footer();

                /**
                 * Open the dictonary word when the user clicks on the link
                 */
                const urlParam = window.location.href.split("#")[1];
                if (urlParam && urlParam.startsWith("word")) {
                    const element = document.getElementById(urlParam);
                    if (element) {
                        element.click();
                    }
                }

                document.querySelectorAll("[data-component='care-provider-widget']").forEach((element) => {
                    new FindCareProvider(
                        element,
                        element.getElementsByClassName("care-provider-search-container")[0],
                        element.getElementsByClassName("care-provider-results-container")[0]
                    );
                });
            }
        });
    }
}

window["App"] = new App();
