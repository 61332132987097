export default class Videos {
    constructor() {
        const videoElement = document.getElementsByClassName("ce-video");

        for (let i = 0; i < videoElement.length; i++) {
            new VideoController(videoElement[i]);
        }
    }
}

class VideoController {
    private video: HTMLVideoElement;
    private playButtons: NodeListOf<Element>;
    private muteButton: HTMLElement;
    private hasInteractedWithVideo: boolean;

    constructor(videoContainer: Element) {
        this.video = videoContainer.querySelector("video");
        this.playButtons = videoContainer.querySelectorAll("[data-video-control='play']");
        this.muteButton = videoContainer.querySelector("[data-video-control='mute']");

        const isVideoPlaying = (video) =>
            !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

        const play = () => {
            if (isVideoPlaying(this.video)) {
                this.video.pause();
            } else {
                this.video.play();
            }
            this.playButtons.forEach((button) => {
                button.classList.toggle("icon-pause");
                button.classList.toggle("icon-play");
                if (isVideoPlaying(this.video)) {
                    // button change aria-label
                    button.setAttribute("aria-label", "Pauzeren");
                } else {
                    button.setAttribute("aria-label", "Afspelen");
                }
            });
        };

        const mute = () => {
            if (this.video.muted) {
                this.video.muted = false;
                this.muteButton.setAttribute("aria-label", "Geluid dempen");
            } else {
                this.video.muted = true;
                this.muteButton.setAttribute("aria-label", "Geluid aanzetten");
            }
            this.muteButton.classList.toggle("icon-volume");
            this.muteButton.classList.toggle("icon-mute");
        };

        Array.from(this.playButtons).map((button) => button.addEventListener("click", play));
        this.muteButton.addEventListener("click", mute);

        this.video.addEventListener("play", () => {
            if (!this.hasInteractedWithVideo) {
                videoContainer.classList.add("interacted");
            }
            this.video.classList.add("playing");
            this.playButtons.forEach((button) => {
                button.setAttribute("aria-label", "Pauzeren");
                button.setAttribute("aria-hidden", "false");
            });
            this.muteButton.setAttribute("aria-hidden", "false");
        });
    }
}
